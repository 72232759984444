import "react-phone-number-input/style.css";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthProvider";
import Dashboard from "../../../Dashboard";
import { Button } from "@material-tailwind/react";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/joy/Input";
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomAlert from "../../../../Utility/CustomAlert";

export default function EmployeAjout() {
	const { user } = useAuth();

	const [prenom, setPrenom] = useState("");
	const [nom, setNom] = useState("");
	const [password, setPassword] = useState("");
	const [poste, setPoste] = useState("");
	const [alert, setAlert] = useState("");
	const [severity, setSeverity] = useState("");

	const [postes, setPostes] = useState([]);

	const clearAlert = () => {
		setAlert("");
	};

	useEffect(() => {
		axios
			.get("postes/all")
			.then(function (response) {
				setPostes(response.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const listItems = postes.map((c) => (
		<MenuItem key={c.id} value={c.id}>
			{c.nom_masculin}
		</MenuItem>
	));

	const sumbmitAjout = () => {
		if (
			prenom == null ||
			prenom.trim() == "" ||
			nom == null ||
			nom.trim() == "" ||
			password.trim() == "" ||
			password.trim() == null
		) {
			setAlert("Veuillez entrer tout les champs obligatoires");
			setSeverity("error");
		} else {
			axios
				.post("/employes/add", {
					prenom: prenom,
					nom: nom,
					poste: poste,
					mdp: password,
				})
				.then(function (response) {
					if (response.data.success) {
						setAlert(response.data.message);
						setSeverity("success");
					} else {
						setAlert(response.data.message);
						setSeverity("error");
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		}
		window.scrollTo({top:0, behavior:"smooth"});
	};

	return (
		<>
			{!user ? null : (
				<>
					<Dashboard>
						<div className="w-full">
							<div className="mb-10 flex items-center justify-between flex-wrap gap-5 px-4">
								{alert !== "" ? (
									<CustomAlert
										closeAlert={clearAlert}
										alert={alert}
										alertSeverity={severity}
									/>
								) : null}
								<h1 className="text-2xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-red-600">
									Ajouter un employé
								</h1>
							</div>

							<div className="grid gap-5 px-4 ">
								<FormControl>
									<div>
										Prénom <span className="text-red-600">*</span>
									</div>
									<Input
										className="w-full xl:w-2/3 2xl:w-1/2"
										value={prenom}
										onChange={(newValue) => setPrenom(newValue.target.value)}
									/>
									<br />

									<div>
										Nom <span className="text-red-600">*</span>
									</div>
									<Input
										className="w-full xl:w-2/3 2xl:w-1/2"
										value={nom}
										onChange={(newValue) => setNom(newValue.target.value)}
									/>
									<br />

									<div>
										Mot de passe <span className="text-red-600">*</span>
									</div>
									<Input
										className="w-full xl:w-2/3 2xl:w-1/2"
										value={password}
										type="password"
										onChange={(newValue) => setPassword(newValue.target.value)}
									/>
									<br />

									<div className="mt-3 w-full md:w-1/2">
										<div className="w-full sm:w-1/2 md:w-full xl:w-2/3 2xl:w-1/2">
											<div>Poste</div>
											<Select
												className="w-full bg-[#FBFCFE] border-[#CDD7E1] h-11"
												value={poste}
												onChange={(newValue) => setPoste(newValue.target.value)}
											>
												<MenuItem value=""> </MenuItem>
												{listItems}
											</Select>
										</div>
									</div>
								</FormControl>

								<legend>
									<span className="text-red-600">*</span> Obligatoire
								</legend>
								<Button
									className="bg-red-600 px-2 w-52 mt-5"
									onClick={() => sumbmitAjout()}
								>
									Soumettre
								</Button>
							</div>
						</div>
					</Dashboard>
				</>
			)}
		</>
	);
}
