import { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthProvider";
import Dashboard from "../../../Dashboard";
import DefaultSortableTable from "./DefaultSortableTable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import CustomAlert from "../../../../Utility/CustomAlert";

export default function Postes() {
    const navigate = useNavigate("");

    const { user } = useAuth();
    const [rows, setRows] = useState([]);
    const [alert, setAlert] = useState("");
	const [severity, setSeverity] = useState("");



    const deleteRow = (id) => {
        axios.delete("postes/delete", {data: {"id": id}})
             .then(function(response){
                if(response.data.success){
                  setAlert(response.data.message)
                  setSeverity("success");
                  fetchPostes();
                }
             })
             .catch(function(error){
                console.log(error)
             })
      }

      const clearAlert = () => {
        setAlert("");
      }

    const headCells = [
        {
            id: "nom_masculin",
            label: "Nom Masculin",
            type: "text",
            editable: true,
        },
        {
            id: "nom_feminin",
            label: "Nom Féminin",
            type: "text",
            editable: true,
        },
        {
            id: "role",
            label: "Rôle",
            type: ["Administrateur", "Soin", "Base"],
            editable: true,
        },
    ];


    const fetchPostes = () => {
        axios.get("/postes/all")
        .then((response) => {
            setRows(response.data);
        }).catch((error) => {
            console.log("Erreur lors du fetch des postes : " + error);
        })
    }

    const onEdit = (edited) => {
		axios.post("/postes/edit", {
			id: edited.id,
            nomMasculin: edited.nom_masculin,
            nomFeminin: edited.nom_feminin,
            role: edited.role,
		}).then((response) => {
			if (response.data.success) {
				fetchPostes();
				setSeverity("success");
			} else {
				setSeverity("error");
			}
			setAlert(response.data.message);
		}).catch((err) => {
			setSeverity("error");
			setAlert("Modification du poste impossible");
			console.log("Erreur lors de la modification de poste : " + err);
		})
	}

    useEffect(() => {
       fetchPostes(); 
    },[])

    return (
        user && user.role === "Administrateur" &&
        <Dashboard>
			<div className="w-full mb-3">
            {alert !== "" ? (
									<span className="mb-5 block"><CustomAlert
                                    closeAlert={clearAlert}
                                    alert={alert}
                                    alertSeverity={severity}
                                /></span>
								) : null}
				<h1 className="flex-none float-left text-2xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-red-600">
					Postes
				</h1>
                <Button
					className="float-right p-2 w-28 bg-red-600"
					onClick={() => navigate("/dashboard/postes/add")}
				>
					Ajouter
				</Button>
			</div>
            <DefaultSortableTable
                confirmMessageParam={["nom_masculin"]}
                rows={rows}
                headCells={headCells}
                deleteable = {user.role === "Administrateur"}
				onDeleteRow = {deleteRow}
                rowKeyParam="id"
                editable={user.role === "Administrateur"}
                onEditRow={onEdit}
            />
        </Dashboard>
    );

}