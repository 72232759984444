import { Avatar, Typography } from "@material-tailwind/react";
import { ProfileInfoCard } from "../../widgets/cards/index";
import Dashboard from "../../../Dashboard";
import avatarLogo from "../../../../../images/icons/test.jpg";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SectionCommentaires from "./SectionCommentaires";
import CustomAlert from "../../../../Utility/CustomAlert";
import { useAuth } from "../../../../AuthProvider";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import useResidentEditor from "../../data/ResidentEditor";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function ProfileResident({
  enterInEditMode = false || undefined,
}) {
  const { user } = useAuth();
  const params = useParams();
  const [resident, setResident] = useState(null);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("error");
  const [editType, setEditType] = useState(
    enterInEditMode
      ? user.type === "Famille"
        ? "Famille"
        : "Admin"
      : ""
  );
  const [roomNumbers, setRoomNumbers] = useState([]);
  const fileInput = useRef(null);
  const {
    setPrenom,
    setNom,
    setTypeResidence,
    setChambre,
    setDateNaissance,
    setDateResidence,
    setTelephone,
    setTelephoneUrgence,
    getResidentEdit,
  } = useResidentEditor(resident);

  const navigate = useNavigate();

  const fetchRoomNumbers = async () => {
    try {
      const response = await axios.get("chambres/numeros");
      setRoomNumbers(response.data);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fetchResident = async () => {
    try {
      let url =
        params.id !== undefined
          ? `/residents/get/${params.id}`
          : "/residents/my";
      const response = await axios.get(url);
      if (response.data.success) {
        setAlert("");
        setResident(response.data.resident);
      } else {
        setAlert(response.data.message);
        setSeverity("access");
      }
    } catch (error) {
      console.error("Error checking for resident :", error);
    }
  };
  // TODO s'assurer que certaines valeurs ne sont pas null
  const onEdit = async () => {
    try {
      let editedResident = getResidentEdit();
      const response = await axios.post("/residents/edit", {
        id: resident.id,
        prenom: editedResident.prenom,
        nom: editedResident.nom,
        numeroChambre: editedResident.chambre,
        telephone: editedResident.telephone,
        telephoneUrgence: editedResident.telephoneUrgence,
        dateResidence: editedResident.dateResidence,
        dateNaissance: editedResident.dateNaissance,
        typeResidence: editedResident.typeResidence,
      });
      if (response.data.success) {
        await fetchResident();
        setAlert(response.data.message);
        setSeverity("success");
        setEditType("");
      } else {
        setAlert(response.data.message);
        setSeverity("error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePfpChange = (event) => {
    let file = event.target.files[0];
    const formData = new FormData();
    formData.set("image", file);
    formData.set("residentId", resident.id);
    axios.post("/residents/changeAvatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data; charset=UTF-8"
      }
    }).then((response) => {
      if (response.data.success) {
        setTimeout(() => document.location.reload(), 4000);
        response.data.message += "\nLa page va être actualisée dans quelques secondes.";
        setSeverity("success");
      } else {
        setSeverity("error");
      }
      setAlert(response.data.message);
      
    });
  }

  const formatDate = (date) => {
    let dateObj = new Date(date);

    return dateObj.toISOString().slice(0, 10);
  };

  useEffect(() => {
    fetchResident();
    fetchRoomNumbers();
  }, [setResident, setAlert, setSeverity, setEditType, setRoomNumbers]);

  return (
    <Dashboard>
      {alert && (
        <CustomAlert
          alertSeverity={severity === "access" ? "error" : severity}
          alert={alert}
          closeAlert={() =>
            severity === "access"
              ? navigate("/dashboard/residents")
              : setAlert("")
          }
        />
      )}
      {resident && (
        <div className="flex flex-col mt-2 flex-wrap gap-32 h-full row-span-1">
          <div>
            <div className="mb-10 flex items-center justify-between flex-wrap gap-6">
              <div className="grid grid-cols-4 grow xs:flex xs:flex-row items-center gap-2 xs:gap-6">
                {(user.type === "Famille" || user.role === "Administrateur") && <input ref={fileInput} type="file" className="hidden" onChange={handlePfpChange} />}
                <Avatar
                  src={
                    axios.defaults.baseURL + "/residents/avatar/" + resident.id
                  }
                  onClick={() => {if(user.type === "Famille" || user.role === "Administrateur") fileInput.current.click() }}
                  alt="employe"
                  size="xxl"
                  variant="rounded"
                  className={"col-start-1 justify-self-center col-span-4 rounded-lg shadow-lg shadow-blue-gray-500/40 " + ((user.type === "Famille" || user.role === "Administrateur") ? "cursor-pointer" : "") }
                />
                {editType === "Admin" ? (
                  <div className="flex flex-col col-span-3 gap-2">
                    <TextField
                      variant="outlined"
                      size="small"
                      label={"Prénom"}
                      defaultValue={resident.prenom || ""}
                      required
                      onChange={(event) => setPrenom(event.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      label={"Nom"}
                      required
                      onChange={(event) => setNom(event.target.value)}
                      defaultValue={resident.nom || ""}
                    />
                    <TextField
                      defaultValue={resident.type_residence}
                      label={"Type de résidence"}
                      select
                      required
                      size="small"
                      onChange={(event) => setTypeResidence(event.target.value)}
                    >
                      <MenuItem value="Permanent">Permanent</MenuItem>
                      <MenuItem value="Convalescence">Convalescence</MenuItem>
                    </TextField>
                  </div>
                ) : (
                  <div className="col-span-3">
                    <Typography variant="h5" color="blue-gray" className="mb-1">
                      {resident.prenom || ""} {resident.nom || ""}
                    </Typography>
                    <Typography
                      variant="small"
                      className="font-normal text-blue-gray-600"
                    >
                      {resident.type_residence || ""}
                    </Typography>
                  </div>
                )}
                {(user.type === "Famille" || user.role === "Administrateur") &&
                  (editType !== "" || (
                    <EditOutlined
                      className="cursor-pointer justify-self-end"
                      onClick={() =>
                        user.type === "Famille"
                          ? setEditType("Famille")
                          : setEditType("Admin")
                      }
                    />
                  ))}
              </div>
            </div>
            <div className="grid gap-5 px-4 w-fit">
              <ProfileInfoCard
                title="Information du profil"
                details={{
                  Chambre:
                    editType === "Admin" ? (
                      <TextField
                        variant="outlined"
                        size="small"
                        label={"Chambre"}
                        className="w-24"
                        select
                        required
                        defaultValue={resident.numero}
                        onChange={(event) => setChambre(event.target.value)}
                      >
                        {roomNumbers.map((r) => {
                          return (
                            <MenuItem value={r.numero}>{r.numero}</MenuItem>
                          );
                        })}
                      </TextField>
                    ) : (
                      resident.numero
                    ),
                  "Date de naissance":
                    (editType && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={"Date de naissance"}
                          format="YYYY-MM-DD"
                          size="small"
                          value={
                            resident.date_naissance &&
                            dayjs(formatDate(resident.date_naissance))
                          }
                          onChange={(val) =>
                            setDateNaissance(val && val.format("YYYY-MM-DD"))
                          }
                        />
                      </LocalizationProvider>
                    )) ||
                    (resident.date_naissance &&
                      formatDate(resident.date_naissance)) ||
                    "Aucune",
                  "Date de résidence":
                    editType === "Admin" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          size="small"
                          format="YYYY-MM-DD"
                          required
                          label={"Date de résidence"}
                          value={dayjs(formatDate(resident.date_residence))}
                          onChange={(val) =>
                            setDateResidence(val && val.format("YYYY-MM-DD"))
                          }
                        />
                        <p className="text-red-700">*</p>
                      </LocalizationProvider>
                    ) : (
                      formatDate(resident.date_residence)
                    ),
                  Téléphone:
                    (editType && (
                      <TextField
                        variant="outlined"
                        size="small"
                        label={"Téléphone"}
                        defaultValue={resident.telephone}
                        onChange={(event) => setTelephone(event.target.value)}
                      ></TextField>
                    )) ||
                    resident.telephone ||
                    "Aucun",
                  "Téléphone d'urgence":
                    (editType && (
                      <TextField
                        variant="outlined"
                        size="small"
                        label={"Téléphone d'urgence"}
                        defaultValue={resident.telephone_urgence}
                        onChange={(event) =>
                          setTelephoneUrgence(event.target.value)
                        }
                      ></TextField>
                    )) ||
                    resident.telephone_urgence ||
                    "Aucun",
                }}
              />
            </div>
            {editType && (
              <div className="flex gap-3 mt-6 ml-4">
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => setEditType("")}
                >
                  Annuler
                </Button>
                <Button
                  color="success"
                  variant="outlined"
                  onClick={() => {
                    onEdit();
                  }}
                >
                  Confirmer
                </Button>
              </div>
            )}
          </div>
          <SectionCommentaires residentId={resident.id} />
        </div>
      )}
    </Dashboard>
  );
}
