import { Box } from "@mui/material";
import { Button } from "@material-tailwind/react";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Close from "../../../../../images/icons/Close";
import axios from "axios";
import CustomAlert from "../../../../Utility/CustomAlert";

export default function ChangementMotPasse(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [alert, setAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");

  const soumettrePassword = async () => {
    if (newPassword == newPasswordConfirmation) {
      setOpenAlert(false);
      try {
        const response = await axios.post("/utilisateurs/changePassword", {
          ancienMdp: oldPassword,
          nouveauMdp: newPassword,
        });

        if (response.data.success) {
          setAlert("Le mot de passe a été mis à jour.");
          setNewPassword("");
          setNewPasswordConfirmation("");
          setOldPassword("");
          setAlertSeverity("success");
          setOpenAlert(true);
        } else {
          setAlert(response.data.message);
          setNewPassword("");
          setNewPasswordConfirmation("");
          setOldPassword("");
          setAlertSeverity("error");
          setOpenAlert(true);
        }
      } catch (error) {}
    } else {
      setAlert(
        "Le nouveau mot de passe et la confirmation ne sont pas identiques."
      );
      setNewPassword("");
      setNewPasswordConfirmation("");
      setAlertSeverity("error");
      setOpenAlert(true);
    }
  };

  return (
    <Box
      sx={{
        width:
          props.anchor === "top" || props.anchor === "bottom" ? "auto" : 400,
      }}
      className="p-5"
      role="presentation"
    >
      <span
        className="cursor-pointer float-right mb-2"
        onClick={() => props.toggleDrawer(false)}
      >
        <Close width="25px" height="25px" fill="black"></Close>
      </span>
      {openAlert ? (
        <CustomAlert
          closeAlert={() => setOpenAlert(false)}
          alert={alert}
          alertSeverity={alertSeverity}
        />
      ) : (
        ""
      )}
      <form className="font-medium">
        <h2 className="text-center font-bold text-xl mt-10 mb-10 font-sans">
          Modification du mot de passe
        </h2>
        <span>Mot de passe:</span>
        <br></br>
        <input
          type="password"
          onChange={(ev) => setOldPassword(ev.target.value)}
          value={oldPassword}
          className="border-gray-600 border rounded-md mb-5"
        ></input>
        <br></br>

        <span>Nouveau mot de passe:</span>
        <br></br>
        <input
          type="password"
          onChange={(ev) => setNewPassword(ev.target.value)}
          value={newPassword}
          className="border-gray-600 border rounded-md mb-5"
        ></input>
        <br></br>

        <span>Confirmation du nouveau mot de passe:</span>
        <br></br>
        <input
          type="password"
          onChange={(ev) => setNewPasswordConfirmation(ev.target.value)}
          value={newPasswordConfirmation}
          className="border-gray-600 border rounded-md mb-5"
        ></input>
        <br></br>

        <Button className="bg-red-600 m-1 ml-0" onClick={soumettrePassword}>
          Soumettre
        </Button>
      </form>
    </Box>
  );
}
