import { Typography } from "@material-tailwind/react";
import logo from "../images/residence.png";

export default function footer() {
  return (
    <footer className="w-full bg-[#B11217] p-6">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-[#B11217] text-center md:justify-between">
        <img src={logo} alt="logo-ct" className="w-48 m-auto" draggable="false"></img>
      </div>
      <hr className="my-8 border-black" />
      <Typography color="white" className="text-center font-normal">
        &copy; 2024 Résidence à la Croisée du Bonheur
      </Typography>
      <Typography color="white" className="text-center font-normal">
        Programmé par Maxime Légaré et Olivier Robitaille
      </Typography>
    </footer>
  );
}
