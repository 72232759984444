import { CardBody, Avatar, Typography, Button } from "@material-tailwind/react";
import { ProfileInfoCard } from "../../widgets/cards/index";
import Dashboard from "../../../Dashboard";
import { useAuth } from "../../../../AuthProvider";
import avatarLogo from "../../../../../images/icons/test.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";


export default function ProfileEmploye() {
    const navigate = useNavigate();

    const { user } = useAuth();
    const params = useParams();
    const [employe, setEmploye] = useState(null);
    const [alert, setAlert] = useState("");

    const fetchEmploye = async () => {
        try {
            let url = `/employes/get/${params.id}`
            const response = await axios.get(url);
            if (response.data.success) {
                setAlert("");
                setEmploye(response.data.employe);
            } else {
                setAlert(response.data.message);
            }
        } catch (error) {
            console.error("Erreur lors de la recherche de l'employé :", error);
        }
    };

    useEffect(() => {
        if (isNaN(params.id)) {
            navigate("/dashboard/employes");
        } else {
            fetchEmploye();
        }

    }, [setEmploye, setAlert]);

    return ((
        employe !== null ? (
            <Dashboard>
                <div>
                    <div className="mb-10 flex items-center justify-between flex-wrap gap-6">
                        <div className="flex items-center gap-6">
                            <Avatar
                                src={axios.defaults.baseURL + "/employes/avatar/" + employe.id}
                                alt="employe"
                                size="xxl"
                                variant="rounded"
                                className="rounded-lg shadow-lg shadow-blue-gray-500/40"
                            />
                            <div>
                                <Typography variant="h5" color="blue-gray" className="mb-1">
                                    {employe.prenom} {employe.nom}
                                </Typography>
                                <Typography
                                    variant="small"
                                    className="font-normal text-blue-gray-600"
                                >
                                    {employe.poste}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-5 px-4 w-fit">
                        <ProfileInfoCard
                            title="Information du profil"
                            details={{
                                Email: employe.email,
                                Genre: employe.genre != null ? employe.genre : "Aucun"
                            }}
                        />
                    </div>
                </div>
            </Dashboard>
        ) : null)
    );
}
