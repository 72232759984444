import React, {
	createContext,
	useState,
	useCallback,
	useEffect,
	useRef,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
const urlApi = process.env.REACT_APP_API_URL;

function AuthProvider({ children }) {
	axios.defaults.withCredentials = true;
	axios.defaults.baseURL = urlApi;

	const navigate = useNavigate();
	let baseValue = localStorage.getItem("previousLoggedIn") === "true";
	const previousLoggedIn = useRef(baseValue); // Ref to store previous loggedIn state

	function useOnChangeLogIn(initialValue, destination) {
		const [value, setValue] = useState(initialValue);

		const onChange = useCallback(
			(newValue) => {
				const oldValue = previousLoggedIn.current;
				setValue(newValue);
				localStorage.setItem("previousLoggedIn", JSON.stringify(newValue));
				if (newValue && newValue != oldValue) {
					navigate(destination);
				}
			},
			[navigate, destination]
		);

		return [value, onChange];
	}

	const [loggedIn, setLoggedIn] = useOnChangeLogIn(
		previousLoggedIn.current,
		"dashboard"
	);

	const [user, setUser] = useState(null);
	const [alert, setAlert] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [recaptchaRequested, setRecaptchaRequested] = useState(false);

	const clearAlert = () => {
		setAlert("");
	};

	useEffect(() => {
		// Check authentication status when component mounts
		const checkAuthentication = async () => {
			try {
				const response = await axios.get("/connected");
				if (response.data.success) {
					const nouvelUser = {
						acc_id: response.data.acc_id,
						email: response.data.email,
						role: response.data.role,
						type: response.data.type,
						prenom: response.data.prenom,
						nom: response.data.nom,
						genre: response.data.genre,
						poste: response.data.poste,
					};
					setLoggedIn(true);
					setUser(nouvelUser);
				} else {
					setRecaptchaRequested(response.data.captcha);
					if (loggedIn === true) {
						setAlert("Vous avez été déconnecté.");
					}
					setLoggedIn(false);
				}
			} catch (error) {
				console.error("Error checking authentication:", error);
			} finally {
				setIsLoading(false);
			}
		};

		checkAuthentication();
	}, [setLoggedIn, setAlert]);

	useEffect(() => {
		previousLoggedIn.current = loggedIn;
	}, [loggedIn]);

	const login = async (
		email,
		password,
		recaptcha,
		failedAttempt,
		incrementFailedAttempt
	) => {
		if (failedAttempt >= 3) {
			const captchaValue = recaptcha.current.getValue();
			if (!captchaValue) {
				setAlert("Veuillez cocher la case du reCAPTCHA!");
			} else {
				const res = await axios.post("/verify", {
					captchaValue: captchaValue,
				});

				if (res.data.success) {
					await formLogin(email, password, incrementFailedAttempt);
				} else {
					console.log(res.data);
					setAlert("reCAPTCHA validation failed!");
				}
			}
		} else {
			await formLogin(email, password, incrementFailedAttempt);
		}
	};

	const formLogin = async (email, password, incrementFailedAttempt) => {
		try {
			const response = await axios.post("/checkCredentials", {
				username: email,
				password: password,
			});
			if (response.status === 200 && response.data.success) {
				// email: req.session.user.email,
				//role: req.session.role,
				//type: req.session.acc.poste ? "Employé" : "Famille",
				setLoggedIn(true);
				const nouvelUser = {
					email: response.data.email,
					role: response.data.role,
					type: response.data.type,
					prenom: response.data.prenom,
					nom: response.data.nom,
					genre: response.data.genre,
					poste: response.data.poste,
				};
				setUser(nouvelUser);
				setRecaptchaRequested(false);
				clearAlert();
			} else {
				setLoggedIn(false);
				setAlert(response.data.message);
				incrementFailedAttempt();
			}
		} catch (error) {
			console.error("Authentication error:", error);
			setLoggedIn(false);
		}
	};

	const logout = async () => {
		// Perform logout logic here
		const response = await axios.post("/logoff");
		navigate("/");
		if (response.data.success) {
			setLoggedIn(false);
		}
		setAlert(response.data.message);
		//window.location.reload(); //Pour refresh les composants
	};


	const avatarUrl = axios.defaults.baseURL + "/utilisateurs/avatar";

	if (isLoading) {
		return <></>
	} else {
		return (
			<AuthContext.Provider
				value={{ user, loggedIn, alert, avatarUrl, recaptchaRequested, clearAlert, login, logout }}
			>
				{children}
			</AuthContext.Provider>
		);
	}

}

const useAuth = () => React.useContext(AuthContext);

export { useAuth, AuthProvider };
