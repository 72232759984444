import { Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

export default function TypeChambre({ type }) {
  const [typeChambre, setTypeChambre] = useState();
  const [chambres, setChambres] = useState([]);
  const [plans, setPlans] = useState([]);
  const [images, setImages] = useState([]);

  const getChambres = async () => {
    try {
      const response = await axios.get(
        `/chambres/fromType/${typeChambre.type}`
      );
      if (response.data.message) {
        console.log(response.data.message);
      } else {
        setChambres(response.data);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    axios
      .get(`/chambres/types/${type}`)
      .then((response) => {
        if (response.data.message) {
          console.log(response.data.message);
        } else {
          setTypeChambre(response.data);
        }
        
      })
  }, []);

  useEffect(() => {
    if (typeChambre) {
        const importAll = (r) => r.keys().map(r);
        const plansImages = importAll(
          require.context("../../images/plans", false, /\.(png|jpe?g|svg)$/)
        );
        const imagesChambres = importAll(
            require.context(`../../images/chambres`, false, /\.(png|jpe?g|svg)$/)
        );
        setImages(imagesChambres);
        setPlans(plansImages);
        getChambres();
    }
  },[typeChambre]);

  
  return (
    typeChambre && (
      <div className="min-h-max w-10/12 mt-10 bg-white p-10 rounded-t-xl">
        <h1 className=" mb-10 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-[#B11217] text-center">
          {typeChambre.type}
        </h1>
        <div className="flex flex-col lg:flex-row gap-x-5">
          <ul className="flex flex-col gap-y-3 w-full lg:w-1/2">
            <li className="flex-wrap">
              <Typography
                variant="h6"
                fontWeight="bold"
                color="blue-gray"
              >
                Description :
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight="normal"
              >{typeChambre.description_base}</Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="blue-gray"
              >
                Prix de base :
              </Typography>
              <Typography
              variant="subtitle1"
                fontWeight="normal"
              >{typeChambre.prix_base}$</Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="blue-gray"
              >
                Chambres disponibles :
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight="normal"
              >
              {chambres.length === 0 ? "Aucune chambre n'est actuellement disponible" : chambres
                  .filter((c) => c.disponible)
                  .map((c) => c.numero)
                  .join(", ")}
              </Typography>
            </li>
          </ul>
          <div className="lg:w-1/2 text-center">
          <Typography
                variant="small"
                color="blue-gray"
                className="font-semibold"
              >
                Chambres
              </Typography>
            <div className="flex snap-x snap-mandatory overflow-scroll">
              {plans.map((p) => (
                <div className="snap-center shrink-0 w-full">
                  <img src={p} alt="plan" />
                </div>
              ))}
            </div>
          </div>
        </div>
        {images.length > 0 && <div>
        <h1 className=" my-5 text-lg font-extrabold tracking-tight leading-none md:text-2xl lg:text-3xl text-[#B11217] text-center">
          Galerie
        </h1>
        <div className="flex snap-x snap-mandatory overflow-scroll border-t-2 p-5">
              {images.map((i) => (
                <div className="snap-center shrink-0 w-full">
                  <img src={i} width="400" className="m-auto" alt="plan" />
                </div>
              ))}
              </div>
              </div>
}
      </div>
    )
  );
}
