import Employe from "./Employe";
import Header from "../../header";
import Footer from "../../footer";
import { useEffect, useState } from "react";
import axios from "axios";

const urlApi = process.env.REACT_APP_API_URL;

export default function Equipe() {
	axios.defaults.withCredentials = true;
	axios.defaults.baseURL = urlApi;

	const [employes, setEmployes] = useState([]);

	const getEmployeDisplay = async () => {
		try {
			const response = await axios.get("/employes/display");
			setEmployes(Array.from(response.data));
		} catch (error) {}
	};

	useEffect(() => {
		getEmployeDisplay();
	}, []);

	return (
		<div className="justify-self-stretch bg-gradient-to-b from-[#B11217] w-full flex flex-col items-center">
			<p className="text-center text-5xl font-bold text-white w-full mt-5">
				Notre Équipe!
			</p>
			<div className="flex flex-wrap gap-20 w-11/12 justify-evenly m-20">
				{employes.map((e) => {
					return <Employe id={e.id} poste={e.poste} prenom={e.prenom} nom={e.nom} />;
				})}
			</div>
		</div>
	);
}
