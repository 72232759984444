import { Avatar, Button, Dialog, DialogActions, DialogTitle, Slide } from "@mui/material";
import axios from "axios";
import { CheckRounded, CloseRounded, EditOutlined } from "@mui/icons-material";
import Delete from "../../../../../images/icons/Delete";
import { useAuth } from "../../../../AuthProvider";
import { useState, forwardRef, useEffect } from "react";
import CustomAlert from "../../../../Utility/CustomAlert";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props}/>;
})

export default function Commentaire({
  id,
  auteur,
  commentaire,
  date,
  auteurId,
  onReload
}) {
  const { user } = useAuth();
  const [alerte, setAlerte] = useState("");
  const [severity, setSeverity] = useState("");
  const [confirming, setConfirming] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [comment, setComment] = useState(commentaire);
  const [editing, setEditing] = useState(false);
  const [editedComment, setEditedComment] = useState("");

  const handleDelete = () => {
    setConfirming(true);
  };

  const handleConfirmDelete = () => {
    axios
      .delete("/commentaireResidents/delete", {data: {"id": id}})
      .then((response) => {
        if (response.data.success) {
          setSeverity("success");
          setDeleting(false);
          setComment("");
        } else {
          setSeverity("error");
        }
        setAlerte(response.data.message);
      });
  };

  const handleEdit = () => {
    axios
      .post("/commentaireResidents/edit", {
        id: id,
        commentaire: editedComment,
      })
      .then((response) => {
        if (response.data.success) {
          setSeverity("success");
          fetch();
          setEditing(false);
        } else {
          setSeverity("error");
        }
        setAlerte(response.data.message);
      });
  };

  const fetch = () => {
    axios.get(`/commentaireResidents/get/${id}`).then((response) => {
      if (response.data !== null) {
         setComment(response.data.texte);
      }
    })
  }

  useEffect(() => {},[])

  return (
    comment ?
    <div className="flex flex-col grow-0 h-fit gap-4">
      <Dialog
        open={confirming}
        keepMounted
        TransitionComponent={Transition}
        onTransitionExited={() => {
          if (deleting) handleConfirmDelete();
        }}
      >
        <DialogTitle>{"Supprimer le commentaire?"}</DialogTitle>
        <DialogActions>
          <Button color="error" variant="contained" onClick={() => setConfirming(false)}>Non</Button>
          <Button color="success" variant="contained" onClick={() => {
            setDeleting(true);
            setConfirming(false);
          }}>Oui</Button>
        </DialogActions>
      </Dialog>
      <div className="flex flex-row gap-2">
        <Avatar
          className="rounded-full shadow-lg shadow-blue-gray-500/40 size-20"
          src={axios.defaults.baseURL + "/employes/avatar/" + auteurId}
          alt="employe"
          sx={{ width: 60, height: 60 }}
          variant="circular"
        />
        <div className="flex flex-col sm:flex-row">
          <h1 className="text-base md:text-xl font-bold">{auteur}</h1>
          <p className="text-gray-700 mt-2 sm:mt-[0.4] md:mt-1 text-xs sm:text-base">
            <span className="hidden sm:inline">&nbsp;le&nbsp;</span>
            {new Date(date).toLocaleString("fr-fr", {
              dateStyle: "full",
              timeStyle: "short",
            })}
          </p>
        </div>
        {(user.role === "Administrateur" || user.acc_id === auteurId) &&
          (editing ? (
            <div className="flex-grow h-fit flex gap-1 items-end justify-end">
              <span
                className="hover:cursor-pointer transition-all text-[30px] duration-200 border-red-800 hover:bg-red-200 border-[1px] rounded-full flex justify-center w-fit"
                onClick={() => 
                  setEditing(false)}
              >
                <CloseRounded fontSize="inherit" color="error"></CloseRounded>
              </span>
              <span
                className="hover:cursor-pointer transition-all text-[30px] duration-200 border-green-800 hover:bg-green-200 border-[1px] rounded-full flex justify-center w-fit"
                onClick={() => handleEdit()}
              >
                <CheckRounded fontSize="inherit" color="success"></CheckRounded>
              </span>
            </div>
          ) : (
            <div className="flex-grow h-fit flex gap-1 items-end justify-end">
              {user.acc_id === auteurId && (
                <span className="hover:cursor-pointer transition-all text-[30px] border-gray-300 duration-200 hover:border-black border-[1px] rounded-full flex justify-center w-fit grow-0 h-fit">
                  <EditOutlined
                    fontSize={"inherit"}
                    fill="black"
                    onClick={() => {
                      setEditedComment(comment);
                      setEditing(true);
                    }}
                  ></EditOutlined>
                </span>
              )}
              <span onClick={() => handleDelete()} className="hover:cursor-pointer transition-all duration-200 border-gray-300 hover:border-black border-[1px] rounded-full flex justify-center w-fit h-fit">
                <Delete height="30px" width="30px" fill="black"></Delete>
              </span>
            </div>
          ))}
      </div>
      {alerte && <div className="relative"><CustomAlert alert={alerte} alertSeverity={severity} closeAlert={() => setAlerte("")} /></div>}
      {editing ? (
        <textarea
          className="resize-none w-auto mx-3"
          autoFocus
          value={editedComment}
          onFocus={(e) => {
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          onChange={(e) => {
            setEditedComment(e.target.value);
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
        ></textarea>
      ) : (
        <p className="mx-3">{comment}</p>
      )}
    </div>
    : (alerte ? <CustomAlert alert={alerte} alertSeverity={severity} closeAlert={() => {
      setAlerte("");
      onReload();
    }} /> : "test")
  );
}
