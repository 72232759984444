import * as React from "react";
import Dashboard from "../../../Dashboard";
import { useAuth } from "../../../../AuthProvider";
import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import DefaultSortableTable from "./DefaultSortableTable";
import CustomAlert from "../../../../Utility/CustomAlert";

export default function Residents() {
  const { user } = useAuth();
  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("");
  

  
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const headCells = [
    {
      id: "prenom",
      label: "Prénom",
    },
    {
      id: "nom",
      label: "Nom",
    },
    {
      id: "date_residence",
      label: "Date de résidence",
    },

    {
      id: "telephone_urgence",
      label: "Téléphone d'urgence",
    },
    {
      id: "numero",
      label: "Numéro de chambre",
    },
  ];

  const deleteRow = (id) => {
    axios.delete("residents/delete", {data: {"id": id}})
         .then(function(response){
            if(response.data.success){
              setAlert(response.data.message)
              setSeverity("success");
              fetchResidents();
            }
         })
         .catch(function(error){
            console.log(error)
         })
  }

  const fetchResidents = () => {
    axios
      .get("/residents/all")
      .then(function (response) {
        response.data.map((r) => {
          r.date_residence = formatDate(r.date_residence);
        });
        setRows(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchResidents();
  }, []);

  return (
    <Dashboard>
      <div className="w-full mb-3 ">
      {
					alert && <div className="mb-2"><CustomAlert alert={alert} alertSeverity={severity} closeAlert={() => setAlert("")} /></div>
				}
        <h1 className="flex-none float-left text-2xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-red-600">
          Résidents
        </h1>
        <Button
          className="float-right p-2 w-28 bg-red-600"
          onClick={() => {
            navigate("/dashboard/residents/add");
            window.scrollTo({top:0, behavior:"smooth"});
          }}
        >
          Ajouter
        </Button>
      </div>
      <DefaultSortableTable confirmMessageParam={["prenom", "nom"]} redirectOnEdit onEditRow={(id) => {
        window.scrollTo({top:0, behavior:"smooth"});
        navigate("/dashboard/residents/" + id + "/edit");
        }} editable={user.role === "Administrateur"} deleteable={user.role === "Administrateur"} onDeleteRow={deleteRow} rows={rows} headCells={headCells} onRowNavigation={"/dashboard/residents/"} rowKeyParam="id" rowNavigationParam="id"/>
    </Dashboard>
  );
}
