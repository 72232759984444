import {
  TableSortLabel,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Divider,
  Input,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
} from "@mui/material";
import useSorter from "../../data/Sorter";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Delete from "../../../../../images/icons/Delete";
import { CancelOutlined, CheckOutlined, CheckRounded, CloseRounded, Edit, EditOutlined } from "@mui/icons-material";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props}/>;
})

export default function DefaultSortableTable({
  rows,
  headCells,
  rowKeyParam,
  rowNavigationParam = "",
  onRowNavigation,
  deleteable=false || undefined,
  onDeleteRow,
  editable=false || undefined,
  redirectOnEdit=false || undefined,
  onEditRow,
  confirmMessageParam = []
}) {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");
  const [editing, setEditing] = useState(null);
  const [editedObject, setEditedObject] = useState({});
  const [confirming, setConfirming] = useState(false);
  const [confirmRow, setConfirmRow] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");

  const buildEditedObject = (key) => {
    let obj = {};

    rows.map((r) => {
      if (r[rowKeyParam] === key) {
        obj = JSON.parse(JSON.stringify(r));
      }
    });
    return obj;
  }

  const setUpEditing = (key) => {
    setEditing(key);
    setEditedObject(buildEditedObject(key));
  }

  const handleConfirmDelete = () => {
    onDeleteRow(confirmRow[rowKeyParam]);
    setConfirming(false);
  }

  const handleDelete = (row) => {
    setConfirmRow(row);
    setConfirming(true);
    let messageParams = "";
    confirmMessageParam.map((p) => {
      messageParams += row[p] + " "
    })
    setConfirmMessage(`Supprimer ${messageParams || "cette entrée de table "}?`);
  }

  const handleNavigation = (row) => {
    window.scrollTo({top:0, behavior:"smooth"});
    navigate(
      onRowNavigation +
        (row[rowNavigationParam] || rowNavigationParam)
    );
  }

  const sorter = useSorter();
  const navigate = useNavigate();

  return (
    <div>
      <Dialog
        open={confirming}
        keepMounted
        TransitionComponent={Transition}
      >
        <DialogTitle>{confirmMessage}</DialogTitle>
        <DialogActions>
          <Button color="error" variant="contained" onClick={() => setConfirming(false)}>Non</Button>
          <Button color="success" variant="contained" onClick={handleConfirmDelete}>Oui</Button>
        </DialogActions>
      </Dialog>
    
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={(event) => {
                    if (orderBy !== headCell.id) {
                      setOrderBy(headCell.id);
                      setOrder("asc");
                      sorter.sortAscending(rows, headCell.id);
                    } else {
                      if (order === "desc") {
                        setOrder("asc");
                        sorter.sortAscending(rows, headCell.id);
                      } else {
                        setOrder("desc");
                        sorter.sortDescending(rows, headCell.id);
                      }
                    }
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell padding="checkbox"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row[rowKeyParam]}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className={
                "hover:bg-gray-100 transition-colors duration-200"
              }
            >
              {headCells.map((cell) => (
                
                 editing && editing === row[rowKeyParam] && cell.editable ? (
                  <TableCell
                    key={cell.id}
                  >
                    { typeof cell.type === "object" ? (
                      <Select size="small" value={editedObject[cell.id] ?? ""} onChange={(e) => {
                        editedObject[cell.id] = e.target.value;
                        setEditedObject(JSON.parse(JSON.stringify(editedObject)));
                      }}>
                        {cell.type.map((t) => <MenuItem key={t||""} value={t||""} >{t||"N/A"}</MenuItem>)}
                      </Select>
                    ) : (
                    <Input size="small" value={editedObject[cell.id]} type={cell.type} onChange={(e) => {
                      editedObject[cell.id] = e.target.value;
                      setEditedObject(JSON.parse(JSON.stringify(editedObject)));
                    }} />
                    )
}
                  </TableCell>
                ) : (
                <TableCell
                key={cell.id}
                className={onRowNavigation ? "cursor-pointer" : ""}
                onClick={() =>
                  onRowNavigation &&
                  handleNavigation(row)
                }>
                  {row[cell.id]}
                </TableCell>
              )
              ))}
              
                <TableCell key={"options"} padding="checkbox">
                { editing && editing === row[rowKeyParam] ? (
                  <div className="flex gap-1 p-1 justify-around">
                    <span
                      className="hover:cursor-pointer transition-all duration-200 border-red-800 hover:bg-red-200 border-[1px] rounded-full flex justify-center w-fit"
                      onClick={() => setEditing(null)}
                    >
                      <CloseRounded height="25px" width="25px" color="error"></CloseRounded>
                    </span>
                    <span
                      className="hover:cursor-pointer transition-all duration-200 border-green-800 hover:bg-green-200 border-[1px] rounded-full flex justify-center w-fit"
                      onClick={() => {
                        setEditing(null);
                        onEditRow(editedObject);
                      }}
                    >
                      <CheckRounded color="success"></CheckRounded>
                    </span>
                  </div>
                ) : (
                  <div className="flex gap-1 p-1 justify-around">
                  {editable ? (
                    <span
                      className="hover:cursor-pointer transition-all duration-200 border-gray-300 hover:border-black border-[1px] rounded-full flex justify-center w-fit"
                      onClick={() => redirectOnEdit ? onEditRow(row[rowKeyParam]) : setUpEditing(row[rowKeyParam])}
                    >
                      <EditOutlined height="25px" width="25px" fill="black"></EditOutlined>
                    </span>
                    ) : null
                    }
                  {deleteable ? (
                    <span
                      className="hover:cursor-pointer transition-all duration-200 border-gray-300 hover:border-black border-[1px] rounded-full flex justify-center w-fit"
                      onClick={() => handleDelete(row)}
                    >
                      <Delete height="25px" width="25px" fill="black"></Delete>
                    </span>
                    ) : null}
                  </div>
                )}
                </TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}
