import Activity from "../../images/icons/Activity";
import Calm from "../../images/icons/Calm";
import Fireplace from "../../images/icons/Fireplace";

export default function SellingPoint() {
	return (
		<div className="flex flex-wrap justify-center mt-10 mb-10 md:gap-28 lg:gap-40 xl:gap-44">
			<div className="p-4 max-w-sm">
				<div className="flex rounded-lg h-full bg-none p-8 flex-col text-white">
					<div className="h-1/2 rounded-full bg-black w-min m-auto">
						<Activity fill="red" width="60px" height="60px"></Activity>
					</div>
					<p className="h-1/2 text-gray-700 mt-5 font-extrabold tracking-tight leading-none text-2xl text-center">
						Échangez lors d'activités
					</p>
				</div>
			</div>

			<div className="p-4 max-w-sm">
				<div className="flex rounded-lg h-full bg-none p-8 flex-col text-white">
					<div className="h-1/2 rounded-full bg-black w-min m-auto">
						<Calm fill="red" width="60px" height="60px"></Calm>
					</div>
					<p className="h-1/2 text-gray-700 mt-5 font-extrabold tracking-tight leading-none text-2xl text-center">
						Oasis de calme pour tous
					</p>
				</div>
			</div>

			<div className="p-4 max-w-sm">
				<div className="flex rounded-lg h-full bg-none p-8 flex-col text-white">
					<div className="h-1/2 rounded-full bg-black w-min m-auto">
						<Fireplace fill="red" width="60px" height="60px"></Fireplace>
					</div>
					<p className="h-1/2 text-gray-700 mt-5 font-extrabold tracking-tight leading-none text-2xl text-center">
						Accueil doux et convivial
					</p>
				</div>
			</div>
		</div>
	);
}
