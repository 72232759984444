import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";


import { AuthProvider } from "./components/AuthProvider";
import Routing from "./components/Routing";

const urlApi = process.env.REACT_APP_API_URL;

function App() {
	const getCSRFToken = async () => {
		const response = await axios.get("/getCSRFToken");
		axios.defaults.headers.post["X-CSRF-Token"] = response.data.CSRFToken;
		axios.defaults.headers.delete["X-CSRF-Token"] = response.data.CSRFToken;

	};

	

	useEffect(() => {
		getCSRFToken();
	}, []);

	return (
		<BrowserRouter>
			<AuthProvider>
				<Routing></Routing>
			</AuthProvider>
		</BrowserRouter>
	);
}

export { App, urlApi };
