import SendIcon from "@mui/icons-material/Send";
import { Button, Fade, FormControl, Slide, Typography } from "@mui/material";
import { useState } from "react";
import CustomAlert from "../../../../Utility/CustomAlert";
import axios from "axios";

export default function CommentaireAjout({ residentId, showing, close }) {
  const [texte, setTexte] = useState("");
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("");
  const [done, setDone] = useState(false);

  //TODO vérifier pour failles XSS
  const onSend = async () => {
    if (texte.trim() === "") {
      setAlert(
        "Le commentaire ne peut pas être vide. Veulliez entrez du texte."
      );
      setSeverity("error");
    } else {
      try {
        const response = await axios.post("/commentaireResidents/add", {
          commentaire: texte,
          residentId: residentId,
        });
        setDone(response.data.success);
        setSeverity(response.data.success ? "success" : "error");
        setAlert(response.data.message);
      } catch (error) {
        console.log("Erreur lors de l'envoie d'un commentaire : " + error);
      }
    }
  };

  const onAlertClose = (event) => {
    if (done) {
      close();
      setTexte("");
      setDone(false);
    }
    setSeverity("");
  };

  const closeAlerte = () => {
    setAlert("");
  };

  return (
    <Fade
      in={showing}
      className="fixed z-[199] top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50"
      onClick={() => {
        if (done) {
          closeAlerte();
        }
      }}
    >
      <div>
        {(alert || severity) && (
          <Slide
            direction="down"
            in={alert !== ""}
            mountOnEnter
            unmountOnExit
            onExited={() => {
              onAlertClose();
            }}
          >
            <div className="w-full sm:w-1/3 mx-auto">
              <CustomAlert
                alertSeverity={severity}
                alert={alert}
                closeAlert={() => closeAlerte()}
              />
            </div>
          </Slide>
        )}

        <Slide
          direction="down"
          in={showing && !done}
          mountOnEnter
          unmountOnExit
        >
          <div className="fixed top-1/4 left-4 md:left-1/4 z-[200] min-w-[90%] md:min-w-min min-h-80 md:w-1/2 h-fit bg-[#B11217] rounded-lg">
            <FormControl
              required
              className="w-full h-full min-h-80 flex flex-col"
            >
              <div className="pt-4 pl-4">
                <Typography color={"white"} variant="h6">
                  Entrez votre commentaire :
                </Typography>
              </div>
              <div className="grow px-4 flex flex-col">
                <textarea
                  required
                  className="w-full h-full grow rounded-lg p-2 bg-white border-2 border-black"
                  variant="outlined"
                  onChange={(e) => setTexte(e.target.value)}
                />
              </div>

              <div className="flex justify-around my-2">
                <Button color="inherit" variant="contained" onClick={close}>
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  disabled={texte.trim().length === 0}
                  color="success"
                  endIcon={<SendIcon />}
                  onClick={() => onSend()}
                >
                  Envoyer
                </Button>
              </div>
            </FormControl>
          </div>
        </Slide>
      </div>
    </Fade>
  );
}
